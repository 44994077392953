/* eslint-disable @typescript-eslint/naming-convention */

import { RobotDashboardBaseUrlEnum } from '../enums/robot-dashboard-base-url.enum';

export const routingPathBase = '';

// *** Account ***
export const routingPathLogin = 'login';
export const routingPathResetPassword = 'reset-password';
export const routingPathNewDomainRedirect = 'new-domain-redirect';


// ** Robot ***
export const routingPathRobotUr = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.UR}/${deviceId}`;
export const routingPathRobotYaskawa = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.YASKAWA}/${deviceId}`;
export const routingPathRobotEpson = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.EPSON}/${deviceId}`;
export const routingPathRobotTechman = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.TECHMAN}/${deviceId}`;
export const routingPathRobotKassow = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.KASSOW}/${deviceId}`;
export const routingPathRobotKawasaki = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.KAWASAKI}/${deviceId}`;
export const routingPathRobotFanuc = (deviceId: string = ':id') => `${RobotDashboardBaseUrlEnum.FANUC}/${deviceId}`;

// *** Report ***
export const routingPathReport = (deviceId: string = ':id') => `utilization/${deviceId}`;

// *** Alarms ***
export const routingPathAlarms = (deviceId: string = ':id') => `alerts/${deviceId}`;
export const routingPathAlarmsYaskawa = (deviceId: string = ':id') => `alarms_yaskawa/${deviceId}`;

// *** Logs ***
export const routingPathLogs = (deviceId: string = ':id') => `logs/${deviceId}`;

// *** Charts ***
export const routingPathCharts = (deviceId: string = ':id') => `charts/${deviceId}`;
export const routingPathChartsDetails = (
  deviceId: string | null = ':id',
  paramName: string = ':paramName',
) => {
  if (deviceId) {
    return `charts/${deviceId}/details/${paramName}`;
  } else {
    return `details/${paramName}`;
  }
};

// *** Palletization ***
export const routingPathPalletization = (deviceId: string = ':id') => `palletisation/${deviceId}`;
export const routingPathPalletizationUniveler = (deviceId: string = ':id') => `palletisation_unilever/${deviceId}`;
export const routingPathPalletizationHMI = (deviceId: string = ':id') => `hmi/${deviceId}`;

// *** Lifetime ***
export const routingPathLifetimeYaskawa = (deviceId: string = ':id') => `lifetime_yaskawa/${deviceId}`;

// *** KPI ***
export const routingPathKPI = (deviceId: string = ':id') => `kpi/${deviceId}`;
export const routingPathKPIScanfil = (deviceId: string = ':id') => `kpi_scanfil/${deviceId}`;
export const routingPathKPIKassow = (deviceId: string = ':id') => `kpi_kassow/${deviceId}`;
export const routingPathKPIBMOptik = (deviceId: string = ':id') => `kpi_bm_optik/${deviceId}`;
export const routingPathKPIBMOptik2 = (deviceId: string = ':id') => `kpi_bm_optik2/${deviceId}`;
export const routingPathKPIHutchinson = (deviceId: string = ':id') => `kpi_hutchinson/${deviceId}`;
export const routingPathKPIHutchinsonTools = (deviceId: string = ':id') => `kpi_hutchinson_tools/${deviceId}`;

// *** OEE ***
export const routingPathOEE = (deviceId: string = ':id') => `oee/${deviceId}`;

// *** Errors ***
export const routingPathErrors = (deviceId: string = ':id') => `diagnostic/${deviceId}`;

// *** Flight Records ***
export const routingPathFlightRecords = (deviceId: string = ':id') => `troubleshooting/${deviceId}`;

// *** Service Packs ***
export const routingPathServicePacks = (
  deviceId: string = ':id',
  servicePackId: string = ':servicePackId',
) => `devices/${deviceId}/service_packs/${servicePackId}`;

// *** Score ***
export const routingPathScore = (deviceId: string = ':id') => `maintenance/${deviceId}`;
export const routingPathPreventive = (deviceId: string = ':id') => `maintenance/${deviceId}/preventive`;
export const routingPathLifetime = (deviceId: string = ':id') => `maintenance/${deviceId}/lifetime`;
export const routingPathBackups = (deviceId: string = ':id') => `maintenance/${deviceId}/backups`;
export const routingPathProgramViewer = (deviceId: string = ':id') => `maintenance/${deviceId}/program-viewer`;
export const routingPathBackupsDiff = (deviceId: string = ':id') => `maintenance/${deviceId}/programs-comparison`;
export const routingPathSupportFile = (deviceId: string = ':id') => `maintenance/${deviceId}/support-file`;

// *** Settings ***
export const routingPathSettings = (deviceId: string = ':id') => `settings/${deviceId}`;

// *** Playground ***
export const routingPathPlayground = (deviceId: string = ':id') => `cm-frontend-playground/${deviceId}`;
